import React, { useState, useRef } from 'react';
import LazyLoad from 'react-lazy-load';

const CloudinaryPlayer = ({ src, posterUrl }) => {
	const videoRef = useRef(null);
	const [showPlayButton, setShowPlayButton] = useState(true);

	const handlePlayClick = () => {
		const video = videoRef.current;
		video.play();
	};

	return (
		<LazyLoad>
			<div className="c-cloudinary-video">
				<video
					ref={videoRef}
					controls
					src={src}
					poster={posterUrl}
					onPlay={() => setShowPlayButton(false)}
				/>
				{showPlayButton && (
					<div className="c-cloudinary-video__play-button" onClick={handlePlayClick}>
						<img src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/play.png" alt="play" />
					</div>
				)}
			</div>
		</LazyLoad>
	);
};

export default CloudinaryPlayer;
